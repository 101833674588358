<template>
  <div class="row">
    <div class="col-12">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="설비 목록"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :selection="isMultiple ? 'multiple' : 'none'"
          :data="grid.data"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          :checkClickFlag="false"
          :hideBottom="true"
          :isExcelDown="false"
          rowKey="minEquipmentCheckId"
          checkDisableColumn="disable"
          @rowClick="rowClick"
        >
          <!-- @rowClick="rowClick" -->
          <template slot="table-button">
            <q-btn-group outline >
              <q-btn
                v-if="editable && !disabled && isMultiple"
                icon="help"
                color="deep-purple-6"
                text-color="white"
                class="custom-btn"
                align="center"
                round>
                <q-tooltip anchor="bottom left" self="top left">
                  <div class="tooltipCustomTop">
                    버튼설명
                  </div>
                  <div class="tooltipCustom">
                  o 선택완료 : 체크박스를 선택하여 점검완료 하는 버튼입니다.<br>
                  o 일괄저장 : 설비목록과 설비별 점검항목, 개선관리를 일괄적으로 저장하는 버튼입니다.<br>
                  </div>
                </q-tooltip>
              </q-btn>
              <c-btn v-if="editable && !disabled && isMultiple" :showLoading="false" label="선택완료" icon="save" @btnClicked="resultComplete" />
              <c-btn v-if="editable && !disabled && !isMultiple" :showLoading="false" label="완료" icon="check"  @btnClicked="resultComplete2" />
              <c-btn
                v-if="editable && !disabled"
                :url="updateUrl"
                :isSubmit="isSave"
                :param="grid.data"
                mappingType="PUT"
                label="일괄저장"
                icon="save"
                @beforeAction="saveInspection"
                @btnCallback="saveCallback" 
              />
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name === 'sopMocName'">
              <q-btn
                class="tableinnerBtn tableinnerUserSearch"
                :class="{'tableinnerUserSearch-left': true,  'tableinnerUserSearch-right': true }"
                flat
                :editable="editable"
                :disable="props.row.disable"
                color="blue-6"
                :label="props.row.sopMocName"
                icon="search"
                @click.stop="linkClick(props.row)" />
            </template>
            <template v-if="col.name === 'complete' && isMultiple">
              <q-btn
                class="glossy"
                round 
                size="sm"
                :editable="editable"
                :disable="props.row.disable"
                color="blue-6"
                icon="save"
                @click.stop="resultComplete3(props.row)" />
            </template>
          </template>
        </c-table>
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-tab
        type="tabcard"
        :tabItems.sync="tabItems"
        :inlineLabel="true"
        v-model="tab"
        align="left"
        @tabClick="tabClick"
      >
        <template v-slot:default="tab">
          <component
            :is="tab.component"
            :result.sync="result"
            :imprTabInfo.sync="imprTabInfo"
            :research.sync="research"
            :isMultiple="isMultiple"
            @getDetail="getDetail"
          />
        </template>
      </c-tab>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-result-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        title: '',
        noPlan: 'N',
        selectData: [],
        equipmentCd: '',
        minEquipmentCheckId: '',
        checkDate: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      result: {
        minEquipmentCheckId: '',
        plantCd: '',
        plantName: '',
        checkDeptCd: '',
        checkDeptName: '',  
        checkUserId: '',
        checkUserName: '',
        sopMocId: '',
        sopMocName: '',
        equipmentCheckKindCd: null,
        equipmentCheckKindNm: '',
        checkStatusCd: '',
        checkScheduleUserName: '', 
        checkScheduleUserId: '',
        checkScheduleDate: '',
        equipmentTypeCd: '', 
        equipmentCd: '', 
        equipmentName: '',
        inspectionCycleName: '',
        inspectionCycleCd: '',
        recentInspectionDate: '',
        relatedLaws: '',
        regUserId: '',
        chgUserId: '',
        noPlanResultEnrollFlag: 'N',
        checkResultCd: null,
        checkCompleteFlag: null,
        checkDate: '',
        equipmentResultList: [],
        equipmentResultCbmList: [],
        equipmentImproveModels: []
      },
      tab: 'resultItemTab',
      tabItems: [
        { name: 'resultItemTab', icon: 'list', label: '설비별 점검항목', component: () => import(`${'./equipmentResultItemTab.vue'}`) },
        { name: 'equipmentImpr', icon: 'health_and_safety', label: '개선 및 정비요청', component: () => import(`${'./equipmentImpr.vue'}`) },
      ],
      grid: {
        columns: [],
        data: [],
        height: '300px'
      },
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      chkResultItems: [],
      chkRsltYnItems: [
        { code: 'Y', codeName: '완료' },
        { code: 'N', codeName: '미완료' },
      ],
      title: '',
      insertUrl: '',
      updateUrl: '',
      getUrl: '',
      isSave: false,
      isSave2: false,
      updateMode: false,
      research: {
        impr: '',
        item: '',
      },
    };
  },
  computed: {
    disabled() {
      let count = 0;
      this.$_.forEach(this.grid.data, _item => {
        if(_item.disable) count++;
      })
      return count === this.grid.data.length;
    },
    isOld() {
      return Boolean(this.popupParam.minEquipmentCheckId);
    },
    isMultiple() {
      return this.popupParam.selectData.length > 0;
    },
    imprTabInfo() {
      return {
        key: this.isMultiple ? this.result.minEquipmentCheckId : this.popupParam.minEquipmentCheckId, // 
        ibmTaskTypeCd: 'ITT0000055',
        requestContents: this.result.equipmentName,
        disabled: this.disabled,
        isOld: this.isOld,
        gridItem: {
          listUrl: selectConfig.sop.min.equipment.result.list.url,
          param: {
            minEquipmentCheckId: this.isMultiple ? this.result.minEquipmentCheckId : this.popupParam.minEquipmentCheckId,
            imprFlag: true,
          },
          title: '점검항목 별 개선사항 목록',
          merge: [
            { index: 0, colName: 'checkItemName' },
          ],
          columns: [
            {
              name: 'checkItemPartName',
              field: 'checkItemPartName',
              label: '부품',
              style: 'width:150px',
              align: 'left',
              sortable: false,
            },
            {
              name: 'checkItemName',
              field: 'checkItemName',
              label: '점검항목 내용',
              align: 'left',
              style: 'width:180px',
              sortable: false,
            },
            {
              name: 'checkMethodName',
              field: 'checkMethodName',
              label: '검사방법',
              align: 'center',
              style: 'width:110px',
              sortable: false,
            },
            {
              name: 'judgmentResultTypeName',
              field: 'judgmentResultTypeName',
              label: '적합/부적합',
              align: 'center',
              style: 'width:90px',
              sortable: false,
            },
            {
              name: 'checkResult',
              field: 'checkResult',
              label: '점검결과',
              align: 'left',
              sortable: false,
            },
            {
              name: 'decisionBasis',
              field: 'decisionBasis',
              label: '판정기준',
              align: 'left',
              style: 'width:200px',
              sortable: false,
            },
            {
              name: 'checkCaution',
              field: 'checkCaution',
              label: '점검시 안전사항',
              align: 'left',
              style: 'width:200px',
              sortable: false,
            },
            {
              name: 'gaugeWarn',
              field: 'gaugeWarn',
              label: '경고값',
              align: 'center',
              type: 'custom',
              style: 'width:85px',
              sortable: false,
            },
            {
              name: 'gaugeExec',
              field: 'gaugeExec',
              label: '실행값',
              align: 'center',
              type: 'custom',
              style: 'width:85px',
              sortable: false,
            },
          ],
          research: this.research,
        },
        gridOther: {
          title: '항목외 개선 목록',
        },
        isFirst: true
      }
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    'research.item'() {
      this.getDetail();
    }
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getUrl = selectConfig.sop.min.equipment.plan.get.url;
      this.updateUrl = transactionConfig.sop.min.equipment.result.updates.url;
      this.completeUrl = transactionConfig.sop.min.equipment.result.completes.url;
      // code setting
    
      // list setting
      this.title = this.popupParam.title;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.selectData.length > 0) {
        this.grid.data = [];
        this.popupParam.selectData.sort(
          function(a,b){
            return (Number(a.equipmentCd.match(/(\d+)/g)[0]) - Number((b.equipmentCd.match(/(\d+)/g)[0])));
          }
        )
        this.$_.forEach(this.popupParam.selectData, _item => {
          this.$http.url = this.getUrl;
          this.$http.type = 'GET';
          this.$http.param = {
            minEquipmentCheckId: _item.minEquipmentCheckId,
            equipmentCd: _item.equipmentCd,
          }
          this.$http.request((_result) => {
            this.grid.data.push(_result.data)
            this.setHeader();
            if (this.isFirst) {
              this.rowClick(this.grid.data[0])
              this.isFirst = false
            } else {
              this.rowClick(this.result)
            }
          },);
        })
      }  else {
        if (this.popupParam.minEquipmentCheckId) {
          this.grid.data = [];
          this.$http.url = this.getUrl;
          this.$http.type = 'GET';
          this.$http.param = {
            minEquipmentCheckId: this.popupParam.minEquipmentCheckId,
            equipmentCd: this.popupParam.equipmentCd,
          }
          this.$http.request((_result) => {
            this.grid.data.push(_result.data);
            this.setHeader();
            this.rowClick(this.grid.data[0])
             
          },);
        }
      }

    },
    setHeader() {
      if (this.popupParam.selectData.length > 0) {
        this.$comm.getComboItems('MIM_CHECK_RESULT_CD2').then(_result => {
          this.chkResultItems = _result;
          this.grid.columns = [
            {
              name: 'equipmentTypeName',
              field: 'equipmentTypeName',
              label: '설비유형',
              style: 'width:100px',
              align: 'center',
              sortable: false
            },
            {
              name: 'equipmentCd',
              field: 'equipmentCd',
              label: '설비코드',
              style: 'width:100px',
              align: 'center',
              sortable: false
            },
            {
              name: 'equipmentName',
              field: 'equipmentName',
              label: '설비명',
              style: 'width:120px',
              align: 'center',
              sortable: false,
            },
            {
              name: 'equipmentCheckKindNm',
              field: 'equipmentCheckKindNm',
              label: '점검유형',
              align: 'center',
              style: 'width:100px',
              sortable: false,
            },
            {
              name: 'checkScheduleUserName',
              field: 'checkScheduleUserName',
              label: '점검예정자',
              align: 'center',
              style: 'width:100px',
              sortable: false,
            },
            {
              name: 'checkScheduleDate',
              field: 'checkScheduleDate',
              label: '점검예정일',
              style: 'width:100px',
              align: 'center',
              sortable: false,
            },
            {
              name: 'checkUserName',
              field: 'checkUserName',
              label: '실제점검자',
              align: 'center',
              style: 'width:100px',
              setHeader: true,
              required: true,
              type: 'user',
              userId: 'checkUserId',
              sortable: false,
            },
            {
              name: 'checkDate',
              field: 'checkDate',
              required: true,
              setHeader: true,
              label: '실제점검일',
              style: 'width:100px',
              type: 'date',
              align: 'center',
              sortable: false,
            },
            {
              name: 'checkResultCd',
              field: 'checkResultCd',
              label: '점검결과',
              required: true,
              align: 'center',
              style: 'width:80px',
              type: 'select',
              setHeader: true,
              comboItems: _result,
              sortable: false,
            },
            {
              name: 'checkResultSummary',
              field: 'checkResultSummary',
              label: '점검결과 요약',
              style: 'width:250px',
              type: 'textarea',
              align: 'left',
              sortable: true
            },
            // {
            //   name: 'click',
            //   field: 'click',
            //   label: '항목클릭',
            //   align: 'center',
            //   style: 'width:50px',
            //   sortable: false,
            //   type: "custom",
            // },
            {
              name: 'complete',
              field: 'complete',
              label: '점검완료',
              align: 'center',
              style: 'width:50px',
              sortable: false,
              type: "custom",
            },
          ]
        });
        this.grid.height = '300px';
      } else {
        this.$comm.getComboItems('MIM_CHECK_RESULT_CD2').then(_result => {
          this.chkResultItems = _result;
          this.grid.columns = [
            {
              name: 'equipmentTypeName',
              field: 'equipmentTypeName',
              label: '설비유형',
              style: 'width:100px',
              align: 'center',
              sortable: false
            },
            {
              name: 'equipmentCd',
              field: 'equipmentCd',
              label: '설비코드',
              style: 'width:100px',
              align: 'center',
              sortable: false
            },
            {
              name: 'equipmentName',
              field: 'equipmentName',
              label: '설비명',
              style: 'width:120px',
              align: 'center',
              sortable: false,
            },
            {
              name: 'equipmentCheckKindNm',
              field: 'equipmentCheckKindNm',
              label: '점검유형',
              align: 'center',
              style: 'width:100px',
              sortable: false,
            },
            // {
            //   name: 'sopMocName',
            //   field: 'sopMocName',
            //   label: 'MOC 번호',
            //   align: 'center',
            //   style: 'width:180px',
            //   sortable: false,
            //   type: "custom",
            // },
            {
              name: 'checkScheduleUserName',
              field: 'checkScheduleUserName',
              label: '점검예정자',
              align: 'center',
              style: 'width:100px',
              sortable: false,
            },
            {
              name: 'checkScheduleDate',
              field: 'checkScheduleDate',
              label: '점검예정일',
              style: 'width:100px',
              align: 'center',
              sortable: false,
            },
            {
              name: 'checkUserName',
              field: 'checkUserName',
              label: '실제점검자',
              align: 'center',
              style: 'width:100px',
              required: true,
              setHeader: true,
              type: 'user',
              userId: 'checkUserId',
              sortable: false,
            },
            {
              name: 'checkDate',
              field: 'checkDate',
              required: true,
              label: '실제점검일',
              style: 'width:100px',
              type: 'date',
              align: 'center',
              sortable: false,
            },
            {
              name: 'checkResultCd',
              field: 'checkResultCd',
              label: '점검결과',
              required: true,
              align: 'center',
              style: 'width:80px',
              type: 'select',
              setHeader: true,
              comboItems: _result,
              sortable: false,
            },
            {
              name: 'checkResultSummary',
              field: 'checkResultSummary',
              label: '점검결과 요약',
              style: 'width:250px',
              type: 'textarea',
              align: 'left',
              sortable: true
            },
          ]
        });
        this.grid.height = '200px';
      }
    },
    rowClick(row) {
      this.result = row;
      this.$set(this.research, 'impr', uid())
    },
    add() {
      // 초기에는 userId에 tempId를 부여
      this.popupOptions.title = '설비 검색'; // 설비 검색
      this.popupOptions.param = {
        type: 'single'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/equip/equipPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeEquipmentPopup;
    },
    closeEquipmentPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data.selectData && data.selectData.length > 0) {
        this.$_.forEach(data.selectData, _item => {
          this.result.plantName = _item.plantName;
          this.result.deptNm = _item.deptNm,  
          this.result.chkUserNm = data.userNm ? data.userNm : '', 
          this.result.chkUserId = data.userId ? data.userId: '',
          this.result.safFacilityNm = null, 
          this.result.safFacilityCd = null, 
          this.result.equipmentType = _item.equipType, 
          this.result.equipmentCd = _item.equipCd, 
          this.result.equipmentNm = _item.equipNm,
          this.result.chkSchYmd = data.chkYmd ? data.chkYmd : '', 
          this.result.remark = '';
        })
      }
    },
    saveInspection() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      } 
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$_.forEach(this.result.equipmentResultList, _item => {
        if (_item.editFlag === 'C') {
          _item.editFlag = 'U';
        }
      })
    },
    resultComplete() {
      let selectData = this.$refs['table'].selected;
      if (selectData && selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // '안내',
          message: '하나 이상 선택하세요.', 
          type: 'warning',
        });
        return;
      }
      if (this.$comm.validTable(this.grid.columns, selectData)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '점검완료 하시겠습니까?(저장과 함께 완료처리가 됩니다.)',
          // TODO : 점검완료 하시겠습니까?
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(selectData, _item => {
              _item.checkCompleteFlag = 'Y';
            })
            this.$http.url = this.completeUrl;
            this.$http.type = 'PUT';
            this.$http.param = selectData;
            this.$http.request(() => {
              this.$_.forEach(selectData, _item => {
                _item.checkStatusCd = 'MCSC000015';
                _item.disable = true;
              })
              this.$refs['table'].selected = [];
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.getDetail();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
      });
      }
    },
    resultComplete2() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '점검완료 하시겠습니까?(저장과 함께 완료처리가 됩니다.)',
          // TODO : 점검완료 하시겠습니까?
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.grid.data, _item => {
              _item.checkCompleteFlag = 'Y';
            })
            this.$http.url = this.completeUrl;
            this.$http.type = 'PUT';
            this.$http.param = this.grid.data;
            this.$http.request(() => {
              this.grid.data = [];
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.getDetail();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
      });
      }
    },
    // 단일 점검완료
    resultComplete3(data) {
      this.rowClick(data, 'temp')
      if (!data.checkUserId || !data.checkResultCd || !data.checkDate) {
          window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '필수 입력값을 입력해 주세요. [실제점검자, 실제점검일, 점검결과]',
          type: 'warning', // success / info / warning / error
        });
        return;
      }
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '점검완료 하시겠습니까?(저장과 함게 완료처리가 됩니다.)',
          // TODO : 점검완료 하시겠습니까?
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            data.checkCompleteFlag = 'Y';
            this.$http.url = transactionConfig.sop.min.equipment.result.complete.url;
            this.$http.type = 'PUT';
            this.$http.param = data;
            this.$http.request(() => {
              data.disable = true;
              data.checkStatusCd = 'MCSC000015';
              this.$refs['table'].selected = [];
              window.getApp.$emit('APP_REQUEST_SUCCESS');
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
        },
      });
    },
    emit(result) {
      if (result === 'COMPLETE') {
        this.$emit('closePopup', result);
      } else if (result === 'SAVE') {
        this.$emit('closePopup', result);
      } 
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    tabClick() {
       this.$set(this.research, 'impr', uid())
    },
  }
};
</script>
